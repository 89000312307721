<template>
  <v-row class="pb-8">
    <v-col
      cols="12"
      sm="12"
      class="pt-0"
    >
      <carousel />
      <v-row>
        <v-col
          cols="5"
          md="8"
          sm="8"
          lg="10"
          class="pt-0"
        >
          <session-title
            class="pt-8 px-8"
            title="Espelho de Ponto"
          />
        </v-col>
        <v-col
          class="pb-9"
          cols="7"
          md="4"
          sm="4"
          lg="2"
          align-self="center"
        >
          <chat-ti-modal-access-vue />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      class="pt-0 px-12"
    />
    <v-col
      cols="12"
      sm="12"
      class="pt-0 px-12"
    >
      <div class="text-center">
        <v-select
          v-model="selected"
          :items="items"
          label="Selecione o mês"
          item-value="value"
          item-text="label"
          outlined
          color="blue darken-4"
        />
        <v-btn
          color="secondary"
          elevation="2"
          large
          @click="getWorkHours"
        >
          <v-icon class="mr-3">
            mdi-file-move-outline
          </v-icon>Gerar Espelho de Ponto
        </v-btn>
        <v-alert
          v-if="loading"
          outlined
          type="warning"
          class="mt-5 text-center text-justify"
          prominent
        >
          Esse processo pode levar alguns minutos. Por favor, aguarde.
        </v-alert>

        <v-alert
          outlined
          type="info"
          class="mt-5 text-center text-justify"
          prominent
        >
          Para gerar o espelho de ponto, seu navegador precisa aceitar janelas
          popup. Para desbloquear acesse as Configurações > Privacidade e
          Segurança > Permissões e desmarque a caixa "Bloquear janelas popup".
        </v-alert>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { findWorkHours } from '@/services/work-hours-service'
import * as dayjs from 'dayjs'
import { monthsPTBR } from '@/utils/months'
import SessionTitle from '@/components/SessionTitle'
import { mapState } from 'vuex'
import Carousel from '@/components/Carousel'
import ChatTiModalAccessVue from '../../components/ModalViews/ChatTiModalAccess.vue'

export default {
  name: 'Timesheets',
  components: {
    carousel: Carousel,
    'session-title': SessionTitle,
    'chat-ti-modal-access-vue': ChatTiModalAccessVue
  },
  data () {
    return {
      items: [],
      selected: null,
      payMonth: false
    }
  },
  computed: {
    ...mapState('loader', ['loading'])
  },
  created () {
    const currentDate = dayjs()
    const maxMonthsAllowed = 12

    for (let index = 0; index <= maxMonthsAllowed; index++) {
      const monthNumber = currentDate.subtract(index, 'month').month()
      const fullYear = currentDate.subtract(index, 'month').year()

      this.items.push({
        label: `${monthsPTBR[monthNumber]} - ${fullYear}`,
        value: {
          month: monthNumber + 1,
          year: fullYear
        }
      })
    }
  },
  methods: {
    async getWorkHours () {
      if (!this.isFieldValid()) return
      try {
        const data = await findWorkHours(this.selected)
        const file = new Blob([data], { type: 'application/pdf' })
        window.open(URL.createObjectURL(file))
      } catch (error) {
        this.$handleHttpError(error)
      }
    },
    isFieldValid () {
      if (!this.selected) {
        this.$toast.error('Verifique o campo e tente novamente!')
      }
      return !!this.selected
    }
  }
}
</script>
